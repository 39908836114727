var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{key:_vm.mixTableKey,staticClass:"table-kitchen-sink",attrs:{"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":"","loading":_vm.loadingRxToday,"headers":_vm.headers,"item-key":"RxNum","items":_vm.rxList,"search":_vm.search,"items-per-page":30,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50, -1]
    },"sort-by":"FillDate","sort-desc":['true']},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":_vm.getMixtureDetails},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":"6"}},[_c('v-textarea',{staticClass:"pt-5",attrs:{"label":"Notes","placeholder":"Record any relevant Notes","outlined":""},on:{"input":function($event){return _vm.debouncedUpdateRx(item)}},model:{value:(item.notes),callback:function ($$v) {_vm.$set(item, "notes", $$v)},expression:"item.notes"}})],1),_c('td',{attrs:{"colspan":"6"}},[_c('v-dialog',{attrs:{"width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-large":"","color":"error","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Ingredient Log ")])]}}],null,true),model:{value:(_vm.isDialogVisibleIngredients),callback:function ($$v) {_vm.isDialogVisibleIngredients=$$v},expression:"isDialogVisibleIngredients"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Compound Ingredient list ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"table-kitchen-sink",attrs:{"headers":_vm.mixturDetailsHeaders,"items":_vm.mixtureDetails,"search":_vm.search,"items-per-page":15,"item-key":"DIN"},scopedSlots:_vm._u([{key:"item.measured",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"tw-pt-4"},[_c('v-text-field',{attrs:{"label":"measured","placeholder":"Type measured","outlined":""},model:{value:(item.measured),callback:function ($$v) {_vm.$set(item, "measured", $$v)},expression:"item.measured"}})],1)]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.isDialogVisibleIngredients = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)]}},{key:"item.mixStatus",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"tw-pt-4"},[_c('v-select',{attrs:{"items":Object.keys(_vm.statusList),"prepend-icon":_vm.icons.mdiListStatus,"single-line":""},on:{"input":function($event){return _vm.debouncedUpdateRx(item)}},model:{value:(item.mixStatus),callback:function ($$v) {_vm.$set(item, "mixStatus", $$v)},expression:"item.mixStatus"}})],1)]}},{key:"item.initials",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"tw-pt-4"},[_c('v-text-field',{attrs:{"label":"Initials","placeholder":"Type initials","outlined":""},on:{"input":function($event){return _vm.debouncedUpdateRx(item)}},model:{value:(item.initials),callback:function ($$v) {_vm.$set(item, "initials", $$v)},expression:"item.initials"}})],1)]}},_vm._l((_vm.headers.filter(function (noteheader) { return noteheader.hasOwnProperty('checker'); }
      )),function(noteheader){return {key:("item." + (noteheader.value)),fn:function(ref){
      var header = ref.header;
      var value = ref.value;
return [_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(("" + (noteheader.checker(value))))+" ")])]}}}),{key:"item.documentation",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"me-3",attrs:{"color":"success","medium":""},on:{"click":function($event){return _vm.saveRxInfo(item, item['PATIENT ID'])}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])]}},{key:"item.speedDial",fn:function(ref){
      var item = ref.item;
return [_c('div',{attrs:{"id":"create"}},[_c('v-speed-dial',{attrs:{"direction":"right","bottom":"","left":"","open-on-hover":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","dark":"","fab":""},on:{"click":function($event){return _vm.saveRxInfo(item, item['PATIENT ID'])}},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")]):_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiAccountCircleOutline)+" ")])],1)]},proxy:true}],null,true),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"success"}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPencil))])],1),_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"info"},on:{"click":function($event){return _vm.saveRxInfo(item, item['PATIENT ID'])}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))])],1),_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"error"},on:{"click":function($event){return _vm.saveRxInfo(item, item['PATIENT ID'])}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))])],1)],1)],1)]}},_vm._l((_vm.headers.filter(function (header) { return header.hasOwnProperty('formatter'); }
      )),function(header){return {key:("item." + (header.value)),fn:function(ref){
      var header = ref.header;
      var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg",class:((_vm.statusList[item.mixStatus]) + "--text"),attrs:{"hidden":!item.mixStatus,"small":"","color":_vm.statusList[item.mixStatus] || ''}},[_vm._v(" "+_vm._s(item.mixStatus)+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }