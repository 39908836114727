<template>
  <div
    id="user-view"
  >
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="4"
        class="pa-3 d-flex flex-column"
      >
        <!-- <div
          class="tw-mb-2 tw-card tw-shadow-lg tw-compact tw-side tw-bg-base-100 flex"
        > -->
        <div
          class="tw-mb-2 tw-card tw-shadow-lg tw-compact tw-side tw-bg-base-100 flex"
        >
          <div class="tw-flex-row tw-items-center tw-space-x-4 tw-card-body">
            <div>
              <div
                v-if="patientProfile['GENDER'] === `M`"
                class="tw-avatar"
              >
                <div class="tw-rounded-full tw-w-14 tw-h-14 tw-shadow">
                  <img :src="require(`@/assets/images/avatars/3.png`)">
                </div>
              </div>
              <div
                v-if="patientProfile['GENDER'] === `F`"
                class="tw-avatar"
              >
                <div class="tw-rounded-full tw-w-14 tw-h-14 tw-shadow">
                  <img :src="require(`@/assets/images/avatars/8.png`)">
                </div>
              </div>
            </div>
            <div>
              <h2 class="tw-card-title">
                {{ patientProfile['FIRST NAME'] }} {{ patientProfile['LAST NAME'] }}
              </h2>
              <p class="tw-text-base-content tw-text-opacity-40">
                {{ this.$moment(this.patientProfile['DATE OF BIRTH']).format('YYYY-MM-DD') }}
              </p>
            </div>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="4"
        class="pa-3 d-flex flex-column"
      >
        <div
          class="tw-mb-2 tw-card tw-shadow-lg tw-compact tw-side tw-bg-base-100 flex"
        >
          <div class="tw-flex-row tw-items-center tw-space-x-4 tw-card-body">
            <div
              v-if="blisterMedsList.length > 0"
              class="tw-stat"
            >
              <div class="tw-stat-figure tw-text-secondary">
                <v-img
                  contain
                  max-width="50"
                  class="tw-mt-4 mx-auto align-center justify-center"
                  src="@/assets/images/icons/Icon Color_3.png"
                ></v-img>
              </div>
              <div class="tw-stat-title">
                Patient Properties
              </div>
              <div class="tw-stat-value tw-break-words tw-text-lg">
                Blister Pack Patient
              </div>
            </div>
          </div>
          <div class="tw-flex-row tw-items-center tw-space-x-4 tw-card-body">
            <div
              v-if="blisterMedsList.length === 0"
              class="tw-stat"
            >
              <div class="tw-stat-figure tw-text-secondary">
                <v-img
                  contain
                  max-width="50"
                  class="tw-mt-4 mx-auto align-center justify-center"
                  src="@/assets/images/icons/Icon Color_3.png"
                ></v-img>
              </div>
              <div class="tw-stat-title">
                Patient Properties
              </div>
              <div class="tw-stat-value tw-break-words tw-text-lg">
                Not a Blister Pack Patient
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="4"
        class="pa-3 d-flex flex-column"
      >
        <div
          class="tw-mb-2 tw-card tw-shadow-lg tw-compact tw-side tw-bg-base-100 tw-justify-center flex"
        >
          <div class="tw-flex-row tw-items-center tw-space-x-4 tw-card-body tw-justify-center">
            <div class="tw-flex tw-justify-center">
              <button
                class="tw-btn tw-btn-success"
                @click="selectedShipPatient"
              >
                <v-icon
                  left
                  dark
                >
                  {{ icons.mdiTruck }}
                </v-icon>
                Ship to Patient
              </button>
              <button
                class="tw-btn tw-btn-success tw-ml-3"
                @click="selectedMedscheckPatient"
              >
                <v-icon
                  left
                  dark
                >
                  {{ icons.mdiPill }}
                </v-icon>
                Medscheck
              </button>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <v-tabs
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item :key="1">
            <v-card
              id="medListId"
              :key="componentKeyMedList"
            >
              <medication-table
                :selected="selectedMedication"
                @medicationupdatelist="selected"
              >
              </medication-table>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="2">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-btn
                      color="success"
                      @click="refreshpatientDocument"
                    >
                      Refresh
                      <v-icon
                        dark
                        right
                      >
                        {{ icons.mdiRefresh }}
                      </v-icon><p class="tw-text-gray-600"></p>
                    </v-btn>
                  </v-col>
                  <v-col
                    md="4"
                    offset-md="4"
                  >
                    <v-text-field
                      v-model="search"
                      :append-icon="icons.mdiMagnify"
                      label="Search"
                      single-line
                      hide-details
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-data-table
                :key="patientDocumentKey"
                :loading="loadingpatientDocument"
                class="table-kitchen-sink"
                :search="search"
                :headers="patientDocumentHeaders"
                :items="patientDocumentItems"
                :sort-by="['modifiedAt']"
                :sort-desc="['true']"
              >
                <template
                  #[`item.amendpatientDocumentCompleted`]="{ item }"
                  :loading="loadingAmend"
                >
                  <v-icon
                    color="success"
                    medium
                    class="me-3"
                    @click="loadPatientProfile(item['patientID'], item['_id'])"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon><p class="tw-text-gray-600"></p>
                </template>
                <template
                  v-for="header in patientDocumentHeaders.filter((header) =>
                    header.hasOwnProperty('formatter')
                  )"
                  v-slot:[`item.${header.value}`]="{ header, value }"
                >
                  {{ header.formatter(value) }}
                </template>
                <template #[`item.duplicateCompleted`]="{ item }">
                  <v-icon
                    medium
                    class="me-3"
                    @click="duplicate(item['patientID'], item['_id'])"
                  >
                    {{ icons.mdiContentDuplicate }}
                  </v-icon><p class="tw-text-gray-600"></p>
                </template>
                <template #[`item.dlPDF`]="{ item, index }">
                  <v-icon
                    color="primary"
                    medium
                    class="me-3"
                    @click="downloadPdf(item)"
                  >
                    {{ icons.mdiCloudDownload }}
                  </v-icon><p class="tw-text-gray-600"></p>
                </template>
                <template v-slot:no-data>
                  <v-btn
                    color="primary"
                    @click="refreshAllLists"
                  >
                    Reset
                  </v-btn>
                </template>

                <template #[`item.status`]="{item}">
                  <v-chip
                    color="primary"
                    outlined
                    pill
                  >
                    {{ item.status }}
                  </v-chip>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="3">
            <v-card>
              <v-data-table
                :headers="blisterpackHeaders"
                :items="blisterMedsList"
                :items-per-page="15"
                item-key="RxNumber"
                class="elevation-1"
              >
              </v-data-table>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="4">
            <v-btn
              color="primary"
              @click="getlistOfDins"
            >
              Get Data
            </v-btn>
            <div
              class="tw-mb-2 tw-card tw-shadow-lg tw-compact tw-side tw-bg-base-100"
            >
              <div class="tw-flex-row tw-items-center tw-space-x-4 tw-card-body">
                <v-list flat>
                  <h2 class="tw-card-title tw-text-black">
                    Indications
                  </h2>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in listOfIndications"
                      :key="i"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          class="tw-break-words"
                          v-text="item"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </div>
            <div
              class="tw-mb-2 tw-card tw-shadow-lg tw-compact tw-side tw-bg-base-100"
            >
              <div class="tw-flex-row tw-items-center tw-space-x-4 tw-card-body">
                <v-list flat>
                  <h2 class="tw-card-title tw-text-black">
                    Drug Classes
                  </h2>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(item, i) in listOfClasses"
                      :key="i"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          class="tw-break-normal"
                          v-text="item"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </div>
          </v-tab-item>
          <v-tab-item :key="5">
            <div

              class="tw-mb-2 tw-card tw-shadow-lg tw-compact tw-side tw-bg-base-100"
            >
              <div class="tw-flex-row tw-items-center tw-space-x-4 tw-card-body">
                <div>
                  <v-list>
                    <v-list-item
                      dense
                      class="px-0 mb-n2"
                    >
                      <span class="font-weight-medium me-2">Patient Name:</span>
                      <span class="text--secondary">{{ patientProfile['FIRST NAME'] }} {{ patientProfile['LAST NAME'] }}</span>
                    </v-list-item>
                    <v-list-item
                      dense
                      class="px-0 mb-n2"
                    >
                      <span class="font-weight-medium text-no-wrap me-2">Email:</span>
                      <span class="text--secondary">{{ patientProfile['EMAIL ADDRESS'] }}</span>
                    </v-list-item>
                    <v-list-item
                      dense
                      class="px-0 mb-n2"
                    >
                      <span class="font-weight-medium me-2">Date of Birth</span>
                      <span class="text--secondary text-capitalize">{{ this.$moment(this.patientProfile['DATE OF BIRTH']).format('YYYY-MM-DD') }}</span>
                    </v-list-item>
                    <v-list-item
                      dense
                      class="px-0 mb-n2"
                    >
                      <span class="font-weight-medium me-2">Health Card Number:</span>
                      <span class="text--secondary">{{ patientProfile['HEALTH CARD NUMBER'] }}</span>
                    </v-list-item>
                    <v-list-item
                      dense
                      class="px-0 mb-n2"
                    >
                      <span class="font-weight-medium me-2">Contact:</span>
                      <span class="text--secondary">{{ patientProfile['TELEPHONE NUMBER'] }}</span>
                    </v-list-item>
                    <v-list-item
                      dense
                      class="px-0 mb-n2"
                    >
                      <span class="font-weight-medium me-2">Gender:</span>
                      <span class="text--secondary">{{ patientProfile['GENDER'] }}</span>
                    </v-list-item>
                    <v-list-item
                      dense
                      class="px-0 mb-n2"
                    >
                      <span class="font-weight-medium me-2">City:</span>
                      <span class="text--secondary">{{ patientProfile['CITY/TOWN'] }}</span>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </div>
            <!-- <user-tab-notifications></user-tab-notifications> -->
          </v-tab-item>
          <v-tab-item :key="6">
            <div
              v-show="$store.state.User.pharmacy._id == '5b539d500d356d3568fddad2' || $store.state.User.pharmacy._id == '5c86a701e18d087fc087192f'"
              class="tw-mb-2 tw-card tw-shadow-lg tw-compact tw-side tw-bg-base-100"
            >
              <encounters></encounters>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div

              class="tw-mb-2 tw-card tw-shadow-lg tw-compact tw-side tw-bg-base-100"
            >
              <compoundsTable></compoundsTable>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div

              class="tw-mb-2 tw-card tw-shadow-lg tw-compact tw-side tw-bg-base-100"
            >
              <plans></plans>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <!-- <v-btn
      text
      @click="setTabNumber"
    >
      Previous
    </v-btn> -->
  </div>
</template>

<script>
import moment from 'moment'
import { ref } from '@vue/composition-api'
import {
  mdiBank,
  mdiAccountOutline,
  mdiLockOutline,
  mdiTruck,
  mdiLinkVariant,
  mdiRefresh,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiContentDuplicate,
  mdiCloudDownload,
  mdiMagnify,
  mdiArrowRight,
  mdiArrowLeft,
  mdiNewspaperVariantMultipleOutline,
  mdiPencilBoxMultipleOutline,
  mdiHeadLightbulbOutline,
  mdiAirplaneTakeoff,
  mdiPrescription,
  mdiAccountVoice,
  mdiPill,
  mdiCalendarMonthOutline,
  mdiFax,
  mdiBookmarkOutline,
  mdiChefHat,
} from '@mdi/js'
import { mapGetters, mapState } from 'vuex'
import axios from 'axios'
import qs from 'qs'
import { v4 as uuidv4 } from 'uuid'
import { pgdrugService, stateService, shippingService } from '@/render/api/zarya'

// import ApexChartMobileComparison from './ApexChartMobileComparison.vue'

import medicationTable from '@/components/phoxTables/drug/multiDrug.vue'
import { getTherapeuticClassByMeds } from '@/render/api/hpc/therapeuticClass'

import config from '@/render/api/zarya/config'
import compoundsTable from './components/compoundsTable.vue'
import plans from './components/plans.vue'
import encounters from './components/encounters.vue'

export default {
  name: 'PatientProfileView',
  components: {
    medicationTable,
    encounters,
    compoundsTable,
    plans,

    // ApexChartMobileComparison,
  },
  setup() {
    const userTab = ref('')
    const search = ref('')

    // const preTab = () => {
    //   userTab.value = 6
    // }
    const tabs = [
      { icon: mdiAccountOutline, title: 'Prescriptions' },
      { icon: mdiLockOutline, title: 'Documents' },
      { icon: mdiLinkVariant, title: 'Compliance Grid' },
      { icon: mdiAccountVoice, title: 'Indications' },
      { icon: mdiBookmarkOutline, title: 'Billing & Plan' },
      { icon: mdiPrescription, title: 'Encounters' },
      { icon: mdiChefHat, title: 'Compounds' },
      { icon: mdiBank, title: 'Plans' },

      //   { icon: mdiBellOutline, title: 'Notifications' },
    ]

    return {
      search,
      userTab,
      tabs,

      // preTab,
    }
  },
  data() {
    return {
      WS_KEY: '',
      patientEncounters: [],
      hasKey: false,
      orderNumber: '',
      hasData: false,
      listOfClasses: [],
      listOfIndications: [],
      listOfDins: [],
      isValid: true,
      iframeUrl: '',
      shipmentInfo: { ShipmentDetails: {} },
      dateFormat: 'YYYY-MM-DD',
      dateTimeFormat: 'YYYY-MM-DD HH:mm',
      patientDocumentKey: 0,
      patientProfileKey: 0,
      componentKeyMedList: 0,
      loadingpatientDocument: true,
      selectedMedication: [],
      patientProfile: {},
      blisterMedsList: [],
      blisterpackHeaders: [
        {
          text: 'Brand Name',
          value: 'BrandName',
        },
        {
          text: 'Strength',
          value: 'Strength',
        },
        {
          text: 'Directions',
          value: 'SIGFull',
        },
        {
          text: 'Brk',
          value: 'Brk',
        },
        {
          text: 'Noon',
          value: 'Noon',
        },
        {
          text: 'Supp',
          value: 'Supp',
        },
        {
          text: 'Bed',
          value: 'Bed',
        },
        {
          text: 'Description',
          value: 'description',
        },
      ],
      icons: {
        mdiBank,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiContentDuplicate,
        mdiCloudDownload,
        mdiMagnify,
        mdiRefresh,
        mdiArrowRight,
        mdiArrowLeft,
        mdiNewspaperVariantMultipleOutline,
        mdiPencilBoxMultipleOutline,
        mdiHeadLightbulbOutline,
        mdiAirplaneTakeoff,
        mdiPrescription,
        mdiAccountVoice,
        mdiPill,
        mdiCalendarMonthOutline,
        mdiFax,
        mdiTruck,
        mdiChefHat,
      },
      patientDocumentItems: [],
      patientDocumentHeaders: [
        {
          text: 'Form Type',
          value: 'formType',
        },
        {
          text: 'Date Submitted',
          value: 'modifiedAt',
          sortable: true,
          formatter: x => (x ? moment(x).format(this.dateTimeFormat) : null),
        },
        {
          text: 'Document Status',
          value: 'status',
        },
        {
          text: 'Download PDF',
          value: 'dlPDF',
          align: 'center',
        },
      ],
    }
  },
  watch: {
    // eslint-disable-next-line space-before-function-paren
    // eslint-disable-next-line quote-props
    $route: {
      handler(to, from) {
        if (to !== from && to.query.tab) {
          this.userTab = to.query.tab
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.selectedPatient()
    this.wsKeyCheck()
    this.searchBlisterMeds()
    this.initialState()

    // this.getlistOfDins()

    this.$root.$on('Refresh_Table', patientID => {
      // console.log('Refresh_Table', patientID)
      this.wsKeyCheck()
      this.initialState()
      this.selectedPatient()
      this.searchBlisterMeds()

      // this.setTabNumber()

      // this.getlistOfDins()
    })

    // this.$root.$on('setPatientProfileTab', tab => {
    //   // console.log('Refresh_Table', tab)
    //   this.setTabNumber()
    // })
  },
  computed: {
    ...mapGetters(['PatientProfile', 'FormClass', 'UserProfile', 'PharmacyProfile']),
    ...mapState(['Patient', 'User']),
  },
  methods: {
    async getPatientEncounters(patientID) {
      this.patientEncounters = await this.$root.$system.DBAdapterConn.getEncounterHistory(patientID)
    },

    // async setTabNumber() {
    //   // if (this.prevRoute.path === '/dashboards/mixtures') {
    //   //   this.userTab.value = 6
    //   //   console.log('setTabNumber', this.userTab)
    //   //   console.log(this.prevRoute.path)
    //   // } else {
    //   //   this.userTab.value = this.prevRoute.query.tab
    //   // }
    //   debugger
    //   this.userTab = 6
    // },

    // add 0 for nexxsyss to din to get the drug class from health canada
    // make an object that contains all the data
    // add a counter for how mnay days each rx covered

    getlistOfDins() {
      this.listOfClasses = []
      this.listOfIndications = []
      this.listOfDins = []
      this.$store.state.Patient.medications.forEach(item => {
        this.listOfDins.push(item.DIN)
        this.getClassFromDin(item.GenericName, item.DIN)
        this.getIndicationFromGenericName(item.GenericName, item.DIN)
      })

      return this.listOfDins
    },
    getClassFromDin(genericName, din) {
      const config = {
        method: 'get',
        url: `https://health-products.canada.ca/api/drug/drugproduct/?din=${din}`,
        headers: {},
      }
      axios(config)
        .then(response => {
          this.getTherapeuticFromDin(response.data[0].drug_code, genericName)
        })
        .catch(error => {
          console.log(error)
        })
    },
    getTherapeuticFromDin(drugcode, genericName) {
      const config = {
        method: 'get',
        url: `https://health-products.canada.ca/api/drug/therapeuticclass/?lang=en&type=json&id=${drugcode}`,
        headers: {},
      }
      axios(config)
        .then(response => {
          this.listOfClasses.push(`${genericName}: ${response.data[0].tc_ahfs}`)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getIndicationFromGenericName(genericName, din) {
      const token = await this.$auth.getTokenSilently()
      const firstGenericName = genericName.split('/')[0].replace(/ .*/, '')
      const indication = await pgdrugService.getDrugByGeneric(firstGenericName, token)
      if (Object.keys(indication).length > 0) {
        this.listOfIndications.push(`${din}: ${firstGenericName} -  ${indication[0].indication}`)
      } else {
        this.listOfIndications.push(`${din}: ${firstGenericName} - No indication found`)
      }
    },

    async wsKeyCheck() {
      const token = await this.$auth.getTokenSilently()
      await axios
        .put(
          `${config.api}pharmacy/verifyWsKey`,
          {
            userId: this.$store.getters.PharmacyProfile._id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(async res => {
          if (!res.data) {
            this.dialog = true
          } else {
            this.dialog = false
            this.hasKey = true
            this.WS_KEY = res.data
          }
        })
    },
    async selectedMedscheckPatient() {
      this.$router.push({
        path: '/services/medscheck',
      })
    },
    async selectedShipPatient() {
      ;(this.patientProfile = {
        ...this.PatientProfile,
      }),
        (this.patientProfile['DATE OF BIRTH'] = this.$moment(this.patientProfile['DATE OF BIRTH']).format('YYYY-MM-DD'))
      this.patientProfile.rphfirstname = `${this.UserProfile.firstName} ${this.UserProfile.lastName}`
      this.orderNumber = `${this.$store.getters.PharmacyProfile.accNumber}-${
        this.$store.getters.PatientProfile.PatientID
      }-${uuidv4()}`
      await this.twoShipCall()
      this.hasData = false
      window.open(this.iframeUrl)
    },
    async twoShipCall() {
      const payload = {
        WS_Key: this.WS_KEY,
        Sender: {
          CompanyName: this.PharmacyProfile.name,
          PersonName: `${this.UserProfile.firstName} ${this.UserProfile.lastName}`,
          Country: 'CA',
          State: 'ON',
          City: this.PharmacyProfile.address.city,
          PostalCode: this.PharmacyProfile.address.code,
          Address1: `${this.PharmacyProfile.address.streetNumber} ${this.PharmacyProfile.address.streetName}`,
          Address2: this.PharmacyProfile.address.unit,
          Telephone: this.PharmacyProfile.phone,
          Email: (this.PharmacyProfile && this.PharmacyProfile.email) || '',
          IsResidential: false,
        },
        Recipient: {
          PersonName: `${this.PatientProfile['FIRST NAME']} ${this.PatientProfile['LAST NAME']}`,
          Country: 'CA',
          State: 'ON',
          City: this.PatientProfile['CITY/TOWN'],
          PostalCode: this.PatientProfile['POSTAL CODE'],
          Address1: `${this.PatientProfile['STREET NUMBER']} ${this.PatientProfile['STREET NAME']}`,
          Address2: this.PatientProfile['UNIT NUMBER'],
          Telephone: this.PatientProfile['TELEPHONE NUMBER'],
          Email: this.PatientProfile['EMAIL ADDRESS'],
          IsResidential: true,
        },
        LocationId: '0',
        Packages: [
          {
            Weight: 2,

            // Width: 12,
            // Length: 12,
            // Height: 12,
          },
        ],
        Skids: [
          {
            Weight: 0,
          },
        ],
        OrderNumber: this.orderNumber,

        // CallbackURL: "https://dev-api.pharmaguide.ca/shipping", //live
        // CallbackURL: `https://0f84c7af91ee.ngrok.io/shipping`, //dev test
        CallbackURL: `${config.api}shipping`, // dev
        RequestPickup: false,
        EmailLabels: false,
        EmailsList: ['walid@pharmaguide.ca'],
        Webhooks: [
          {
            // Uri: "https://dev-api.pharmaguide.ca/shipping", //live
            // Uri: "https://0f84c7af91ee.ngrok.io/shipping", //dev
            Uri: `${config.api}shipping`, // dev
            Username: 'string',
            Password: 'string',
            Type: 'ShipmentStatusChange',
          },
        ],
        CheckAndDeleteFromOnHold: true,
      }
      await axios.post('https://ship2api.2ship.com/api/GetEditURL_V1', { ...payload }).then(async res => {
        const url = res.data.TwoShipURL
        const urlParams = await url.substring(url.indexOf('?') + 1)
        this.iframeUrl = `https://ship2.2ship.com/io2ship.aspx?HideMenu=true&RemovePadding=true&${urlParams}`
      })
    },
    async getWsKey() {
      const payload = {
        WS_Key: '2A9BF143-B8DE-4569-AAEC-8E63FB3BFA2E',
        UserName: this.PharmacyProfile.email,
        Password: `${Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2)}`,
        Email: this.PharmacyProfile.email,
        Company: this.PharmacyProfile.name,
        FirstName: this.UserProfile.firstName,
        LastName: this.UserProfile.lastName,
        City: this.PharmacyProfile.address.city,
        Address1: `${this.PharmacyProfile.address.streetNumber} ${this.PharmacyProfile.address.streetName}`,
        CountryCode: 'CA',
        PostalCode: this.PharmacyProfile.address.code,
        Province: 'ON',
        Telephone: this.PharmacyProfile.phone,
      }

      await axios({
        method: 'post',
        url: 'https://ship2.2ship.com/signup/user/create',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; chartset=UTF-8',
          Accept: 'application/json, text/javascript, */*; q=0.01',
          'accept-language': 'en-US,en;q=0.9,ro;q=0.8;hu;q=0.7',
        },
        data: qs.stringify(payload),
      })
        .then(async res => {
          this.WS_KEY = res.data
          const token = await this.$auth.getTokenSilently()
          await axios
            .put(
              `${config.api}pharmacy/addWsKey`,
              {
                wsKey: this.WS_KEY,
                accNumber: this.$store.getters.UserProfile.accreditationNumbers[0][0],
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )
            .then(res => {
              this.dialog = false
              this.hasKey = true
            })
        })
        .catch(err => console.log(err))
    },
    async downloadPdf(pdfRef) {
      try {
        const token = await this.$auth.getTokenSilently()
        await this.$store.dispatch('pdfDownload', {
          token,
          pdfRef: pdfRef.state.pdfRef,
        })
      } catch (err) {
        console.error(err)
      }
    },
    async initialState() {
      this.loading = true
      this.patientDocumentItems = []
      this.listOfClasses = []
      this.listOfIndications = []
      this.listOfDins = []
      await this.loadStateInfo()
      this.loading = false
    },
    async loadStateInfo() {
      const token = await this.$auth.getTokenSilently()
      this.pharmacyId = await this.$store.state.User.pharmacy._id
      this.patientID = await this.$store.state.Patient.data.PatientID
      this.patientDocumentItems = await stateService.getStateByPatientID(this.patientID, this.pharmacyId, token)
      this.loadingpatientDocument = false
    },
    async refreshAllLists() {
      this.loadingpatientDocument = true
      this.patientDocumentItems = []
      await this.loadStateInfo()
      this.loadingpatientDocument = false
    },
    async refreshpatientDocument() {
      this.loadingpatientDocument = true
      this.patientDocumentItems = []
      const token = await this.$auth.getTokenSilently()
      this.pharmacyId = await this.$store.state.User.pharmacy._id
      this.patientID = await this.$store.state.Patient.data.PatientID
      this.patientDocumentItems = await stateService.getStateByPatientID(this.patientID, this.pharmacyId, token)
      this.loadingpatientDocument = false
    },
    selectedPatient() {
      this.patientProfileKey += 1
      this.componentKeyMedList += 1

      this.patientProfile = {
        ...this.$store.state.Patient.data,
      }
    },
    selected(selectedMedication) {
      this.selectedMedication = selectedMedication
    },
    async searchBlisterMeds() {
      this.blisterMedsList =
        (await this.$root.$system.DBAdapterConn.getBlisterPackMedications(this.patientProfile.PatientID)) || []
    },
    async getRxNames() {
      const listOfMedsToCheck = this.patientProfile.selectedMedication
        .filter(med => !med['DISCONTINUED MEDS'] && med.GenericName)
        .map(med => med.GenericName)

      await getTherapeuticClassByMeds(listOfMedsToCheck).then(drugInteractions => {
        if (drugInteractions) {
          const drugArray = Array.from(drugInteractions)
          this.patientProfile.drugInteractionList = drugArray.map(index => ({
            interactionDescription: index.interactionDescription,
            interactionDrugs: {
              med1: index.interactionDrugNames[0],
              med2: index.interactionDrugNames[1],
            },
          }))
          this.populateDrugInteractions(this.patientProfile.drugInteractionList)
          this.patientProfile = {
            ...this.patientProfile,
            ...this.patientProfile.drugInteractionList,
            'DATE OF BIRTH': this.$moment(this.patientProfile['DATE OF BIRTH']).format('YYYY-MM-DD'),
          }
        } else {
          this.patientProfile.drugInteractionList = 'No drug interactions indicated.'
        }
      })
      this.activeStep = 2
    },
    populateDrugInteractions(drugInteractionList) {
      drugInteractionList = this.patientProfile.drugInteractionList.forEach((interaction, index) => {
        const cur = index + 1
        drugInteractionList[
          `interaction${cur}`
        ] = `${interaction.interactionDrugs.med1} and ${interaction.interactionDrugs.med2}: ${interaction.interactionDescription}`
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
