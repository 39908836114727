<template>
  <div id="wrap">
    <div id="chart-year">
      <vue-apex-charts
        type="bar"
        height="400"
        :options="this.dataxaxis"
        :series="this.dataseries"
      />
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapState } from 'vuex'
import { chartColors, lineAreaChartSpline } from './chartoptions'

export default {
  name: 'Encounters',
  components: {
    VueApexCharts,
  },
  data() {
    return {
      chartColors,

      lineAreaChartSpline,
      seriesValues: [],
      dataseries: [],
      dataxaxis: {},
      xaxis: [],
      patientEncounters: [],
    }
  },

  computed: {
    ...mapState(['Patient']),
  },
  mounted() {
    this.getPatientEncounters()
    this.$root.$on('Refresh_Table', patientID => {
      this.getPatientEncounters()
    })
  },
  methods: {
    async getPatientEncounters() {
      this.patientEncounters = await this.$root.$system.DBAdapterConn.getEncounterHistory(
        this.$store.state.Patient.data.PatientID,
      )
      this.seriesValues = this.patientEncounters.map(item => item.RxCount)
      this.xaxis = this.patientEncounters.map(item => item.Date)
      this.chartSeries()
      this.chartXAxis()
    },
    chartSeries() {
      this.dataseries = [
        {
          name: 'Prescriptions',
          data: this.seriesValues,
        },
      ]

      //   console.log(this.dataseries)
    },
    chartXAxis() {
      this.dataxaxis = {
        ...this.lineAreaChartSpline.chartOptions,
        xaxis: {
          categories: this.xaxis,
        },
      }

      //   console.log(this.dataxaxis.xaxis.categories)
    },
  },
}
</script>
