<template>
  <div id="plansTable">
    <v-data-table
      :headers="headers"
      :items="patientPlans"
      :items-per-page="10"
    >
      <!-- Relationship -->
      <template #[`item.RelationShip`]="{item}">
        <v-chip
          small
          :color="statusColor[relationShip[item.RelationShip]]"
          :class="`${statusColor[relationShip[item.RelationShip]]}--text`"
          class="v-chip-light-bg"
        >
          {{ relationShip[item.RelationShip] }}
        </v-chip>
      </template>

      <!-- status -->
      <template #[`item.Inactive`]="{item}">
        <v-chip
          small
          :color="statusColor[status[item.Inactive]]"
          :class="`${statusColor[status[item.Inactive]]}--text`"
          class="v-chip-light-bg"
        >
          {{ status[item.Inactive] }}
        </v-chip>
      </template>

      <!-- Smart Card -->
      <template #[`item.SmartCard`]="{item}">
        <v-chip
          small
          :color="statusColor[smartCard[item.SmartCard]]"
          :class="`${statusColor[smartCard[item.SmartCard]]}--text`"
          class="v-chip-light-bg"
        >
          {{ smartCard[item.SmartCard] }}
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PatientPlans',
  components: {},
  setup() {
    const statusColor = {
      Active: 'primary',
      Inactive: 'error',
      'Smart Card': 'success',
      'N/A': 'grey',
      Cardholder: 'primary',
      Spouse: 'primary',
      Child: 'primary',
      Other: 'primary',
    }

    return {
      headers: [
        { text: 'Plan Order', value: 'PlanOrder' },
        { text: 'Plan Code', value: 'PlanCode' },
        { text: 'Carrier ID', value: 'CarrierID' },
        { text: 'Group ID', value: 'GroupID' },
        { text: 'Plan ID', value: 'PlanID' },
        { text: 'Relationship', value: 'RelationShip' },
        { text: 'Status', value: 'Inactive' },
        { text: 'Smart Card', value: 'SmartCard' },
      ],

      // for nexxsys it is recevered as it flags active whereas in fillware it flags inactive. that is why 0 is active and 1 is inactive whereas in fillware it is true is inactive and false is active

      status: {
        false: 'Active',
        true: 'Inactive',
        0: 'Inactive',
        1: 'Active',
      },
      smartCard: {
        false: 'N/A',
        true: 'Smart Card',
      },
      relationShip: {
        0: 'Cardholder',
        1: 'Spouse',
        2: 'Child',
        3: 'Other',
        null: 'Cardholder',
      },
      statusColor,
    }
  },
  data() {
    return {
      patientPlans: [],
    }
  },

  computed: {
    ...mapState(['Patient']),
  },
  mounted() {
    this.getPatientPlans()
    this.$root.$on('Refresh_Table', patientID => {
      this.getPatientPlans()
    })
  },
  methods: {
    async getPatientPlans() {
      this.patientPlans =
        await this.$root.$system.DBAdapterConn.getPatientPlans(
          this.$store.state.Patient.data.PatientID,
        )
    },
  },
}
</script>
