// eslint-disable-next-line import/prefer-default-export

export const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#e0cffe',
    series2: '#b992fe',
    series1: '#ab7efd',
  },
}

// https://apexcharts.com/docs/options/chart/events/
export const lineAreaChartSpline = {
  chartOptions: {
    chart: {
      toolbar: {
        show: true,
      },
      events: {
        dataPointSelection(event, chart, config) {
          console.log('point index', config.dataPointIndex)
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: false,
      curve: 'straight',
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'left',
      fontSize: '14px',
      fontFamily: '"Inter", sans-serif',
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
    },

    yaxis: {
      // opposite: isRtl
    },
    fill: {
      opacity: 1,
      type: 'solid',
    },
    tooltip: {
      shared: false,
    },
    colors: [chartColors.area.series3, chartColors.area.series2, chartColors.area.series1],
  },
}
