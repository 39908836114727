<template>
  <div>
    <v-data-table
      :key="mixTableKey"
      v-model="selected"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
      :loading="loadingRxToday"
      :headers="headers"
      item-key="RxNum"
      :items="rxList"
      :search="search"
      :items-per-page="30"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50, -1]
      }"
      sort-by="FillDate"

      :sort-desc="['true']"
      class="table-kitchen-sink"
      @item-expanded="getMixtureDetails"
    >
      <template
        v-slot:expanded-item="{ headers, item }"
      >
        <td
          colspan="6"
        >
          <v-textarea
            v-model="item.notes"
            class="pt-5"
            label="Notes"
            placeholder="Record any relevant Notes"
            outlined
            @input="debouncedUpdateRx(item)"
          ></v-textarea>
        </td>
        <td
          colspan="6"
        >
          <v-dialog
            v-model="isDialogVisibleIngredients"
            width="800px"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                x-large
                color="error"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Ingredient Log
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                Compound Ingredient list
              </v-card-title>

              <v-container fluid>
                <v-data-table
                  :headers="mixturDetailsHeaders"
                  :items="mixtureDetails"
                  :search="search"
                  :items-per-page="15"
                  class="table-kitchen-sink"
                  item-key="DIN"
                >
                  <template
                    #[`item.measured`]="{ item }"
                  >
                    <div class="tw-pt-4">
                      <v-text-field
                        v-model="item.measured"
                        label="measured"
                        placeholder="Type measured"
                        outlined
                      ></v-text-field>
                      <!-- @input="debouncedUpdateRx(item)" -->
                      <!-- @change="updateChipStatus(item)" -->
                    </div>
                  </template>
                </v-data-table>
              </v-container>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="isDialogVisibleIngredients = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </td>
      </template>

      <template
        #[`item.mixStatus`]="{ item }"
      >
        <div class="tw-pt-4">
          <v-select
            v-model="item.mixStatus"
            :items="Object.keys(statusList)"
            :prepend-icon="icons.mdiListStatus"
            single-line
            @input="debouncedUpdateRx(item)"
          ></v-select>
          <!-- @change="updateChipStatus(item)" -->
        </div>
      </template>
      <template
        #[`item.initials`]="{ item }"
      >
        <div class="tw-pt-4">
          <v-text-field
            v-model="item.initials"
            label="Initials"
            placeholder="Type initials"
            outlined
            @input="debouncedUpdateRx(item)"
          ></v-text-field>
          <!-- @change="updateChipStatus(item)" -->
        </div>
      </template>

      <template
        v-for="noteheader in headers.filter((noteheader) =>
          noteheader.hasOwnProperty('checker')
        )"
        v-slot:[`item.${noteheader.value}`]="{ header, value }"
      >
        <v-icon color="success">
          {{ `${noteheader.checker(value)}` }}
        </v-icon>
      </template>

      <template
        #[`item.documentation`]="{ item }"
        :loading="loadingAmend"
      >
        <v-icon
          color="success"
          medium
          class="me-3"
          @click="saveRxInfo(item, item['PATIENT ID'])"
        >
          {{ icons.mdiPencilOutline }}
        </v-icon>
      </template>
      <template
        #[`item.speedDial`]="{ item }"
        :loading="loadingAmend"
      >
        <div id="create">
          <v-speed-dial
            v-model="fab"
            direction="right"
            bottom
            left
            open-on-hover
            transition="scale-transition"
          >
            <template
              #activator
              #[`item.speedDial`]="{ item }"
            >
              <v-btn
                v-model="fab"
                color="primary"
                dark
                fab
                @click="saveRxInfo(item, item['PATIENT ID'])"
              >
                <v-icon v-if="fab">
                  {{ icons.mdiClose }}
                </v-icon>
                <v-icon v-else>
                  {{ icons.mdiAccountCircleOutline }}
                </v-icon>
              </v-btn>
            </template>

            <v-btn
              fab
              dark
              small
              color="success"
            >
              <v-icon>{{ icons.mdiPencil }}</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              small
              color="info"
              @click="saveRxInfo(item, item['PATIENT ID'])"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              small
              color="error"
              @click="saveRxInfo(item, item['PATIENT ID'])"
            >
              <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
            </v-btn>
          </v-speed-dial>
        </div>
      </template>
      <!-- RxDate -->
      <template
        v-for="header in headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        {{ header.formatter(value) }}
      </template>

      <!-- status -->
      <template #[`item.status`]="{item}">
        <v-chip
          :hidden="!item.mixStatus"
          small
          class="v-chip-light-bg"
          :color="statusList[item.mixStatus] || ''"
          :class="`${statusList[item.mixStatus]}--text`"
        >
          {{ item.mixStatus }}
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  mdiAccountCircleOutline,
  mdiCalendar,
  mdiCheckboxMarkedCircleOutline,
  mdiCheckCircle,
  mdiClose,
  mdiDeleteOutline,
  mdiListStatus,
  mdiMagnify,
  mdiPencil,
  mdiPencilOutline,
  mdiPlus,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { integerValidator } from '@core/utils/validation'
import { debounce } from 'lodash'
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
import { prescription } from '@/render/api/zarya'
import { momentTimeZone } from '@/util/functions/index'

export default {
  name: 'CompoundList',
  setup() {
    const search = ref('')
    const isDialogVisibleIngredients = ref(false)

    const fab = ref(false)
    const isDialogVisible = ref(false)

    // const date = new Date().toISOString().substr(0, 10)
    const menu = ref(false)
    const modal = ref(false)
    const menuref = ref(null)

    // const dates = new Date().toISOString().substr(0, 10)

    const date = moment().format('YYYY-MM-DD')
    const dates = moment().format('YYYY-MM-DD')

    // const dates = ref([new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)])

    const statusColor = {
      Complete: 'primary',
      Pending: 'success',
      InProgress: 'error',
    }

    return {
      fab,
      isDialogVisible,
      isDialogVisibleIngredients,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiCalendar,
        mdiPencilOutline,
        mdiPlus,
        mdiAccountCircleOutline,
        mdiClose,
        mdiPencil,
        mdiListStatus,
        mdiCheckCircle,
        mdiCheckboxMarkedCircleOutline,
      },
      dates,
      search,
      statusColor,
      date,
      menu,
      modal,
      menuref,
      status: {
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
    }
  },
  computed: {
    ...mapGetters(['PharmacyProfile', 'UserProfile']),
    ...mapState(['User', 'Patient']),
  },
  data() {
    return {
      autofocus: true,
      rxNumber: '',
      mixtureDetails: [],
      mixtureSearchByRx: [],
      statusList: {
        InProgress: 'primary',
        Complete: 'success',
        Pending: 'warning',
        Delayed: 'error',
        Hide: '',
      },
      mixTableKey: 0,
      rxList: [],
      selected: [],
      expanded: [],
      loadingRxToday: true,
      singleExpand: true,

      // dateFormat: 'YYYY-MM-DD HH:mm',
      dateFormat: 'YYYY-MM-DD',
      currentDate: moment().format('YYYY-MM-DD'),
      searchPrescription: '',

      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),

      // fromDate: '',
      // toDate: '',
      from: false,

      // groupby: '',

      groupby: 'PatientName',
      to: false,
      mixturDetailsHeaders: [
        {
          text: 'BrandName',
          value: 'BrandName',
          groupable: false,
          width: '20%',
          align: 'left',
        },
        {
          text: 'Qty',
          value: 'Qty',
          groupable: false,
          width: '10%',
          align: 'left',
        },
        {
          text: 'measured',
          value: 'measured',
          groupable: false,
          width: '10%',
          align: 'left',
        },
        {
          text: 'AcqCost',
          value: 'AcqCost',
          groupable: false,
          width: '10%',
          align: 'left',
        },
        {
          text: 'DIN',
          value: 'DIN',
          groupable: false,
          width: '10%',
          align: 'left',
        },
      ],
      headers: [
        {
          text: 'Status',
          value: 'mixStatus',
          groupable: false,
          width: '15%',
          align: 'left',
        },
        {
          text: 'Initials',
          value: 'initials',
          groupable: false,
          width: '10%',
          align: 'left',
        },
        {
          text: 'Patient Name',
          align: 'left',
          value: 'PatientName',
          width: '15%',
        },
        {
          text: 'Date',
          align: 'center',
          value: 'FillDate',
          width: '10%',
          groupable: false,
          formatter: x => (x ? momentTimeZone(x) : null),
        },
        {
          text: 'Medication',
          align: 'left',
          value: 'Description',
          width: '25%',
        },
        { text: 'STATUS', value: 'status', width: '10%' },
        {
          text: 'Dr Last name',
          align: 'center',
          value: 'DoctorLastName',
          width: '10%',
        },
        {
          text: 'Rx Number',
          align: 'center',
          value: 'RxNum',
          width: '10%',
          groupable: false,
        },
        {
          text: 'Notes',
          value: 'notes',
          groupable: false,
          width: '5%',
          align: 'center',
          checker: x => (x ? mdiCheckCircle : null),
        },
      ],
      prescriptions: [],
      pagination: {
        sortBy: 'date',
      },
      rowsPerPage: [25, 50, { text: 'All', value: -1 }],
    }
  },
  created() {
    this.populatePrescriptionsList()
  },
  mounted() {
    this.populatePrescriptionsList()
    this.$root.$on('Refresh_Table', patientID => {
      // console.log('Refresh_Table', patientID)
      this.populatePrescriptionsList()

      // this.getlistOfDins()
    })
  },
  methods: {
    resetState() {
      this.rxList = []
      this.prescriptions = []
    },
    currentDates() {
      const date = new Date().toISOString().substr(0, 10)

      return date
    },
    currentDateTime() {
      const current = new Date()
      const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      const dateTime = `${date} ${time}`

      return dateTime
    },
    currentTime() {
      const current = new Date()
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`

      return time
    },
    async updateLoad(rxData) {
      const token = await this.$auth.getTokenSilently()

      const payload = {
        _id: this.$store.getters.PharmacyProfile._id + rxData.RxNum,
        pharmacyID: this.$store.getters.PharmacyProfile._id,
        userID: this.$store.getters.UserProfile._id,
        FirstName: rxData.FirstName,
        LastName: rxData.LastName,
        PatientName: rxData.PatientName,
        ID: rxData.ID,
        MixID: rxData.MixID,
        DocID: rxData.DocID,
        PatientID: rxData.PatientID,
        Description: rxData.Description,
        OrigRxNum: rxData.OrigRxNum,
        RxNum: rxData.RxNum,
        FillDate: rxData.FillDate,
        DispQty: rxData.DispQty,
        AAC: rxData.AAC,
        Cost: rxData.Cost,
        Markup: rxData.Markup,
        Fee: rxData.Fee,
        MixTime: rxData.MixTime,
        MixFee: rxData.MixFee,
        Status: rxData.Status,
        DocAddressLoc: rxData.DocAddressLoc,
        DoctorFirstName: rxData.DoctorFirstName,
        DoctorLastName: rxData.DoctorLastName,
        mixStatus: rxData.mixStatus,
        initials: rxData.initials,
        notes: rxData.notes,
      }
      await prescription.createRxStatus(token, payload)
      this.isDialogVisible = false
      await this.$root.$emit('Refresh_Log', this.tab)
      this.successSave = true
      window.setTimeout(() => {
        this.successSave = false
      }, 2000)
    },
    debouncedUpdateRx: debounce(function (rxData) {
      this.updateLoad(rxData)
    }, 500),
    async startLoad() {
      const token = await this.$auth.getTokenSilently()
      this.loadingRxToday = true
      const payload = []

      this.prescriptions.forEach(rxData => {
        payload.push({
          _id: this.$store.getters.PharmacyProfile._id + rxData.RxNum,
          pharmacyID: this.$store.getters.PharmacyProfile._id,
          userID: this.$store.getters.UserProfile._id,
          FirstName: rxData.FirstName,
          LastName: rxData.LastName,
          PatientName: rxData.PatientName,
          ID: rxData.ID,
          MixID: rxData.MixID,
          DocID: rxData.DocID,
          PatientID: rxData.PatientID,
          Description: rxData.Description,
          OrigRxNum: rxData.OrigRxNum,
          RxNum: rxData.RxNum,
          FillDate: rxData.FillDate,
          DispQty: rxData.DispQty,
          AAC: rxData.AAC,
          Cost: rxData.Cost,
          Markup: rxData.Markup,
          Fee: rxData.Fee,
          MixTime: rxData.MixTime,
          MixFee: rxData.MixFee,
          Status: rxData.Status,
          DocAddressLoc: rxData.DocAddressLoc,
          DoctorFirstName: rxData.DoctorFirstName,
          DoctorLastName: rxData.DoctorLastName,
          mixStatus: rxData.mixStatus,
          initials: rxData.initials,
          notes: rxData.notes,
        })
      })

      await prescription.createRxStatusList(token, payload)
      await this.fetchAllRx()
      this.mixTableKey += 1
      this.loadingRxToday = false
      this.isDialogVisible = false
      await this.$root.$emit('Refresh_Log', this.tab)
      this.successSave = true
      window.setTimeout(() => {
        this.successSave = false
      }, 2000)
    },
    updateChipStatus(item) {
      this.item = {
        ...this.item,
        status: item.mixStatus,
      }
    },
    async fetchAllRx() {
      const accessToken = await this.$auth.getTokenSilently()
      const pharmacyId = this.$store.state.User.pharmacy._id
      const FillDatePost = {
        PatientID: this.$store.state.Patient.data.PatientID,
        pharmacyID: pharmacyId,
      }

      this.rxList = await prescription.getRxByPatientID(accessToken, FillDatePost)

      return this.rxList
    },
    async saveRxInfo(rx, patientID) {
      this.$store.dispatch('savePatientData', {
        ...(await this.$root.$system.DBAdapterConn.getPatientData(patientID)),
        'SELECTED RX': [rx],
      })
      this.$root.$emit('Refresh_Status_Bar', patientID)

      this.$router.push({
        name: 'forms-prescriptionstoday-prescriptionstodaylist',
      })
    },
    async saveRxInfo(rx, patientID) {
      this.$store.dispatch('savePatientData', {
        ...(await this.$root.$system.DBAdapterConn.getPatientData(patientID)),
        selectedMedication: [rx],
      })
      this.$root.$emit('Refresh_Status_Bar', patientID)

      this.$router.push({
        name: 'document-renew-rx',
      })
    },
    async refreshRxList() {
      this.$refs.dialog.save(this.dates)
      this.modal = false
      this.fromDate = this.dates
      this.prescriptions =
        (await this.$root.$system.DBAdapterConn.getMixturesBytPatient(this.$store.state.Patient.data.PatientID)) || []
      this.startLoad()
    },
    async populatePrescriptionsList() {
      this.prescriptions =
        (await this.$root.$system.DBAdapterConn.getMixturesBytPatient(this.$store.state.Patient.data.PatientID)) || []
      this.startLoad()
      this.loadingRxToday = false
    },
    async getMixtureDetails({ item }) {
      this.mixtureDetails = (await this.$root.$system.DBAdapterConn.getMixtureDetails(item.RxNum)) || []
    },
    async getMixtureByRx() {
      this.mixtureSearchByRx = (await this.$root.$system.DBAdapterConn.getMixtureByRx(this.rxNumber)) || []
      this.dates = momentTimeZone(this.mixtureSearchByRx[0].FillDate)
      this.isDialogVisible = false
      this.refreshRxList()
      this.search = this.rxNumber
      this.rxNumber = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}

.v-btn--fab.v-size--default {
  height: 40px !important;
  width: 40px !important;
}
#create {
  height: 10px;
}
</style>
